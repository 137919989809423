exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-guides-affordable-housing-scheme-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/guides/affordable-housing-scheme/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-guides-affordable-housing-scheme-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-guides-first-time-home-buyer-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/guides/first-time-home-buyer/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-guides-first-time-home-buyer-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-guides-how-to-start-investing-in-stock-market-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/guides/How-to-start-investing-in-stock-market/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-guides-how-to-start-investing-in-stock-market-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-guides-lulu-mall-in-india-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/guides/lulu-mall-in-india/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-guides-lulu-mall-in-india-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-guides-rajiv-gandhi-international-airport-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/guides/rajiv-gandhi-international-airport/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-guides-rajiv-gandhi-international-airport-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-guides-real-estate-investment-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/guides/real-estate-investment/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-guides-real-estate-investment-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-guides-solar-power-home-setup-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/guides/solar-power-home-setup/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-guides-solar-power-home-setup-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-homes-home-decor-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/homes/home-decor/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-homes-home-decor-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-homes-home-maintenance-tips-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/homes/home-maintenance-tips/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-homes-home-maintenance-tips-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-homes-home-organization-ideas-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/homes/home-organization-ideas/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-homes-home-organization-ideas-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-homes-interior-design-trends-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/homes/interior-design-trends/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-homes-interior-design-trends-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-homes-modular-kitchen-designs-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/homes/modular-kitchen-designs/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-homes-modular-kitchen-designs-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-homes-smart-home-technology-trends-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/homes/smart-home-technology-trends/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-homes-smart-home-technology-trends-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-lifestyle-apartment-gardening-tips-and-ideas-for-beginners-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/lifestyle/apartment-gardening-tips-and-ideas-for-beginners/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-lifestyle-apartment-gardening-tips-and-ideas-for-beginners-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-lifestyle-asparagus-plant-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/lifestyle/asparagus-plant/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-lifestyle-asparagus-plant-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-lifestyle-money-plant-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/lifestyle/money-plant/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-lifestyle-money-plant-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-lifestyle-passiflora-caerulea-flower-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/lifestyle/passiflora-caerulea-flower/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-lifestyle-passiflora-caerulea-flower-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-lifestyle-simple-rangoli-design-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/lifestyle/simple-rangoli-design/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-lifestyle-simple-rangoli-design-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-cyclonic-storm-fengal-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/cyclonic-storm-fengal/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-cyclonic-storm-fengal-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-delhi-meerut-rrts-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/delhi-meerut-rrts/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-delhi-meerut-rrts-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-trends-gukesh-dommaraju-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/trends/gukesh-dommaraju/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-trends-gukesh-dommaraju-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-trends-navratri-colours-2024-significance-day-wise-list-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/trends/navratri-colours-2024-significance-day-wise-list/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-trends-navratri-colours-2024-significance-day-wise-list-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-trends-noel-tata-new-chairman-of-tata-trusts-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/trends/noel-tata-new-chairman-of-tata-trusts/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-trends-noel-tata-new-chairman-of-tata-trusts-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-trends-ratan-tata-leadership-qualities-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/trends/ratan-tata-leadership-qualities/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-trends-ratan-tata-leadership-qualities-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-trends-uefa-champions-league-fixtures-2024-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/trends/uefa-champions-league-fixtures-2024/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-trends-uefa-champions-league-fixtures-2024-index-mdx" */),
  "component---src-templates-blog-homepage-layout-jsx": () => import("./../../../src/templates/blog-homepage-layout.jsx" /* webpackChunkName: "component---src-templates-blog-homepage-layout-jsx" */),
  "component---src-templates-category-jsx": () => import("./../../../src/templates/category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */)
}

